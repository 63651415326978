import { Paginator } from '@app/classes';
import { CompanyDocument } from '@app/models/documents/company-document.model';
import { EmployeeDocument } from '@app/models/documents/employee-document.model';
import { Employee } from '@app/models/employee/employee.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import { CreatedAtColumn, NameColumn, StatusColumn } from './columns';

const downloadOption: MenuColumnItem = {
    label: 'table.employee-documents.download',
    event: 'download',
};

const remindOption: MenuColumnItem = {
    label: 'table.employee-documents.remind',
    event: 'remind',
};

const deleteOption: MenuColumnItem = {
    label: 'table.employee-documents.delete',
    event: 'delete',
};

export class EmployeeDocumentsTable extends Table {
    links = true;
    showMenuColumnOptions = true;

    constructor(
        protected auth: AuthService,
        private employee: Employee,
        private pathRoot: string = 'documents'
    ) {
        super(auth);
        this.boot();
    }

    getLink(employeeDocument: EmployeeDocument): (string | number)[] {
        return ['/' + this.pathRoot, 'employees', this.employee.id, 'employee-documents', employeeDocument.id];
    }

    getMenuColumnOptionsForRow(employeeDocument: EmployeeDocument): MenuColumnItem[] {
        if (!this.auth.can(CompanyDocument.permission.edit)) {
            return employeeDocument.canBeDownloaded() ? [downloadOption] : [];
        }

        if (!employeeDocument.isComplete()) {
            return [remindOption, deleteOption];
        }

        if (employeeDocument.canBeDownloaded()) {
            return [downloadOption, deleteOption];
        }

        return [deleteOption];
    }

    setColumns(): void {
        this.columns = [new NameColumn(), new CreatedAtColumn(), new StatusColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(EmployeeDocument.param('employee', this.employee.id).with('source'));
    }
}
