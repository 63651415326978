import { TrainingProgramRecord } from '@app/models/training/training-program-record.model';
import { TrainingProgram } from '@app/models/training/training-program.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { AuthService } from '@app/services';
import { Employee } from '@models/employee/employee.model';

export class TrainingProgramColumn extends Column {
    title = 'training.trainingProgram';
    property = 'trainingProgram.name';
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(trainingProgramRecord: TrainingProgramRecord) {
        if (!trainingProgramRecord.trainingProgram.isRecurring || !trainingProgramRecord.sessionNumber) {
            return trainingProgramRecord.trainingProgram.name;
        }

        return `${trainingProgramRecord.trainingProgram.name} #${trainingProgramRecord.sessionNumber} `;
    }

    constructor(
        protected employee: Employee,
        protected auth: AuthService
    ) {
        super();

        if (!this.auth.can(TrainingProgram.managePermission)) {
            return;
        }

        const options = TrainingProgram.query()
            .where('enrolled_employee_id', employee.id)
            .all()
            .then(([programs]) =>
                programs.map((program: TrainingProgram) => ({
                    label: program.name,
                    value: program.id,
                }))
            );

        this.filter = new CheckListFilter('trainingProgramId', options);
    }
}
