import { EmployeeDocument } from '@app/models/documents/employee-document.model';
import { BadgeColumn } from '@app/modules/table/classes/badge-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import { Translatable } from '@app/types/translatable.type';
import { format } from 'date-fns';

export class StatusColumn extends BadgeColumn<EmployeeDocument> {
    title = 'employees.documents.status';
    property = 'status';
    sortable = true;
    type: CellDisplay = CellDisplay.badge;

    getBadgeDisplay(employeeDocument: EmployeeDocument): BadgeDisplay | undefined {
        if (!this.shouldShowBadge(employeeDocument)) {
            return;
        }

        if (employeeDocument.isProcessing()) {
            return {
                type: 'default',
                label: 'PROCESSING',
            };
        }

        return {
            type: 'warning',
            label: employeeDocument.status,
        };
    }

    getCellText(employeeDocument: EmployeeDocument): Translatable | null {
        if (this.shouldShowBadge(employeeDocument)) {
            return '';
        }

        if (employeeDocument.isSignatureRequest() && employeeDocument.isComplete()) {
            return {
                key: 'document.signedOnDate',
                params: {
                    date: format(employeeDocument.completedAt(), 'D MMM YYYY'),
                },
            };
        }

        if (employeeDocument.isAcknowledgement() && employeeDocument.isComplete()) {
            return {
                key: 'document.acknowledgedOnDate',
                params: {
                    date: format(employeeDocument.completedAt(), 'D MMM YYYY'),
                },
            };
        }

        return employeeDocument.status;
    }

    private shouldShowBadge(employeeDocument: EmployeeDocument): boolean {
        return (employeeDocument.isActionable() && !employeeDocument.isComplete()) || employeeDocument.isProcessing();
    }
}
