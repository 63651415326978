import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorParser } from '@app/classes';
import { AuthService, NotifyService, PayrollSettingsService } from '@app/services';
import { Employee } from '@models/employee/employee.model';
import { Reminder } from '@models/employee/reminder.model';
import { TranslateService } from '@ngx-translate/core';
import { PayrollResources } from '@payroll/payroll.resources';
import moment from 'moment';

export interface RoeContact {
    firstName: null | string;
    lastName: null | string;
    areaCode: null | string | undefined;
    phoneNumber: null | string;
    phoneExtensionNumber: null | string | undefined;
}

@Injectable()
export class EmployeeRoeService {
    constructor(
        private http: HttpClient,
        public auth: AuthService,
        public router: Router,
        private notify: NotifyService,
        private translateService: TranslateService,
        public payrollSettingService: PayrollSettingsService
    ) {}

    /**
     * Call the backend to issue an ROE for this employee
     *
     * Returns a promise that resolves to a boolean indicating
     * whether the call succeeded or not.
     *
     * @todo use an employee ROE model with methods (initialize, new, post);
     */
    async issueRoe(employee: Employee, options?: { showErrors?: boolean; redirect?: boolean }): Promise<boolean> {
        const { showErrors, redirect } = Object.assign({ showErrors: true, redirect: true }, options);

        $('.app-loader').show().addClass('active');

        return this.http
            .post(PayrollResources.StoreRoe.replace(':employee', employee.id.toString()), null)
            .toPromise()
            .then((res) => {
                if (redirect) {
                    this.router.navigate(['/payroll', 'records', 'roes', res['data']['id'], 'edit']);
                }

                return true;
            })
            .catch((err) => {
                $('.app-loader').hide().removeClass('active');

                if (showErrors) {
                    this.notify.error(this.parseJsonApiErrorResponse(err) || 'Could not save ROE');
                }

                return false;
            });
    }

    /**
     * Generate a reminder for the admin to issue an ROE for the employee
     */
    async generateReminder(employee: Employee, date: Parameters<typeof moment>[0], notify = true): Promise<Reminder> {
        if (!this.auth.employee || !this.auth.company) {
            throw new Error("Couldn't generate reminder, are you logged in?");
        }

        const params = {
            fullName: employee.fullName,
        };
        const reminder = new Reminder({
            employeeId: this.auth.employee.id,
            creatorId: this.auth.employee.id,
            subject: this.translateService.instant('employees.terminate.reminderToGenerateROESubject', params),
            body: this.translateService.instant('employees.terminate.reminderToGenerateROEBody', params),
            remindAt: moment(date).toDate(),
            repeatOn: 'weekly',
        });

        await reminder.param('company', this.auth.company.id).param('employee', this.auth.employee.id).save();

        if (notify) {
            this.notify.success('Reminder saved');
        }

        return reminder;
    }

    /**
     * @todo standardize error responses from backend
     */
    private parseJsonApiErrorResponse(err: HttpErrorResponse): string {
        return ErrorParser.parse(err.error);
    }
}
