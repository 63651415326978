import { Review } from '@app/models/performance/review.model';
import { BadgeColumn } from '@app/modules/table/classes/badge-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import { ReviewStatusesDisplayOptionsById } from '@app/types/translatables/review-statuses-display.options';

export class StatusColumn extends BadgeColumn<Review> {
    title = 'performance.review-index.tableHeaderLabels.status';
    property = 'status';
    sortField = 'status.id';
    sortable = true;
    type: CellDisplay = CellDisplay.badge;

    getBadgeDisplay(review: Review): BadgeDisplay | undefined {
        return {
            type: review.statusBadgeType,
            label: ReviewStatusesDisplayOptionsById[review.statusId] ?? review.status,
        };
    }
}
