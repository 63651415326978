import { TaskSubtask } from '@app/models/tasks/task-subtask.model';
import { Task } from '@app/models/tasks/task.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Translatable } from '@app/types/translatable.type';

export class SubTasksCountColumn extends Column {
    title = 'table.all-company-tasks.subTasks';
    type: CellDisplay = CellDisplay.string;
    sortable = false;

    getDisplayPropertyValue(task: Task): Translatable {
        if (task.totalSubtasksCount > 0) {
            const completedSubtasksCount = task.subtasks.filter((subTask: TaskSubtask) => subTask.completedAt).length;

            return {
                key: 'employees.tasks-template.completedSubtasks',
                params: { complete: completedSubtasksCount, total: task.totalSubtasksCount },
            };
        }
        return '-';
    }
}
