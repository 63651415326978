import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class DueDateColumn extends Column {
    title = 'performance.review-index.tableHeaderLabels.dueDate';
    property = 'dueAt';
    sortField = 'dueAt';
    sortable = true;
    type: CellDisplay = CellDisplay.date;
}
