import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Translatable } from '@app/types/translatable.type';
import { OnboardingReminder } from '@models/employee/onboarding-reminder.model';

export class DelayDaysColumn extends Column {
    title = 'employees.onboardingSettings.sendAt';
    property = 'delayDays';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(object: OnboardingReminder): Translatable {
        if (object.remindBefore && object.delayDays) {
            return {
                key: 'table.employeeSettingsOnboarding.beforeHire',
                params: {
                    delayDays: object.delayDays,
                },
            };
        }

        if (!object.remindBefore && object.delayDays) {
            return {
                key: 'table.employeeSettingsOnboarding.afterHire',
                params: {
                    delayDays: object.delayDays,
                },
            };
        }

        return 'table.employeeSettingsOnboarding.onHire';
    }
}
