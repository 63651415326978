import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class DateSentColumn extends Column {
    title = 'performance.feedbackColumns.dateSent';
    property = 'createdAt';
    sortField = 'createdAt';
    sortable = true;
    type: CellDisplay = CellDisplay.date;
}
