import { Injectable } from '@angular/core';
import { Paginator, SortDirection } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import {
    DepartmentColumn,
    FullNameColumn,
    HiredAtColumn,
    PositionColumn,
    StatusColumn,
} from '@employees/services/tables/employee-table/columns';
import { Employee } from '@models/employee/employee.model';

@Injectable()
export class MyTeamTable extends Table {
    sortDirection = SortDirection.ASC;
    links = true;
    protected allowedStatuses: string[] = ['active'];
    protected sortProperty = 'lastName';

    constructor(protected auth: AuthService) {
        super(auth);
        this.allowedStatuses = this.getStatusFilterOptionsFromPermissions();
        this.boot();
    }

    getLink(employee: Employee): (string | number)[] {
        return ['/employees', 'directory', employee.id];
    }

    setColumns(): void {
        const companyId = this.auth.company.id;
        const nameColumn = new FullNameColumn();

        const columns = [nameColumn, new PositionColumn(companyId)];

        if (this.allowedStatuses.length > 1) {
            const statusColumn = new StatusColumn();
            statusColumn.changeFilters(this.allowedStatuses);
            columns.push(statusColumn);
        }

        columns.push(new DepartmentColumn(companyId), new HiredAtColumn());

        this.addColumns(...columns);
        this.sortColumn = nameColumn;
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            // HR-815 don't change sorting or filters without consulting product managers
            Employee.param('company', this.auth.company.id)
                .with(['account', 'department', 'job'])
                .where('reportsTo', this.auth.employee.id)
        );
    }

    private getStatusFilterOptionsFromPermissions(): string[] {
        const statuses = new Set<string>();

        // Admin
        if (this.auth.isAdmin()) {
            statuses.add('onboarding');
            statuses.add('active');
            statuses.add('terminated');
            statuses.add('onLeave');
        }

        if (this.auth.hasDirectReports) {
            statuses.add('onboarding');
            statuses.add('active');
            statuses.add('onLeave');
        }

        return Array.from(statuses);
    }
}
