import { Feedback } from '@app/models/performance/feedback.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Translatable } from '@app/types/translatable.type';
import { FeedbackVisibilityDisplayOptions } from '@app/types/translatables/feedback-display.options';

export class VisibilityColumn extends Column {
    title = 'performance.feedbackColumns.visibility';
    property = 'visibility';
    sortField = 'visibility';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(feedback: Feedback): Translatable {
        return FeedbackVisibilityDisplayOptions[feedback.visibility];
    }
}
