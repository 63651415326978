import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class RevieweeColumn extends Column {
    title = 'performance.review-index.tableHeaderLabels.reviewee';
    property = 'subject.fullName';
    sortField = 'subject.firstName';
    sortable = true;
    type: CellDisplay = CellDisplay.string;
}
